import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from '@layouts/content-layout/content-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@modules/auth/views/login/login.component').then((m) => m.LoginComponent),
  },  
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: 'procesos',
        loadChildren: () =>
          import('@modules/procesos/procesos.module').then((m) => m.ProcesosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'casos',
        loadChildren: () =>
          import('@modules/casos/casos.module').then((m) => m.CasosModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'xlsx',
        loadChildren: () =>
          import('@modules/uploadFIleData/uploadFileData.module').then((m) => m.UploadFileDataModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'home',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadComponent: () => import('@modules/users/users.component').then((m) => m.UsersComponent),
        canActivate: [RoleGuard],
        data: { roles: ['SUPER_ADMIN'] },
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
