import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localesEs from '@angular/common/locales/es-DO';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { MenuOptionComponent } from '@shared/components/side-bar/menu-option/menu-option.component'
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from '@layouts/content-layout/content-layout.component';
import { environment } from '../environments/environment';
import { MessageService } from 'primeng/api';
import { CasosService } from './core/services/casos/casos.service';
import { UsersService } from './core/services/api/user.service';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AuthService } from './core/services/auth/auth.service';
import { firstValueFrom } from 'rxjs';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';

registerLocaleData(localesEs, 'es-DO');

export function initializeAppFactory(
  authService: AuthService,
  usersService: UsersService,
  casoService: CasosService
): () => Promise<void> {
  return () => firstValueFrom(authService.authState$)
    .then(async () => {
      if (authService.isLoggedIn) {
        await casoService.loadData();
        await usersService.loadData();
      }
      return Promise.resolve();
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    AuthLayoutComponent,
    MenuOptionComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule], 
    providers: [
      {
        provide: APP_INITIALIZER,
        useFactory: initializeAppFactory,
        deps: [AuthService, UsersService, CasosService],
        multi: true,
      },
      MessageService,
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      {
        provide: LOCALE_ID,
        useValue: 'es-DO'
      },
      provideHttpClient(withInterceptorsFromDi()),
      { 
        provide: ErrorHandler, useValue: Sentry.createErrorHandler() 
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      }
    ]
})
export class AppModule { }
