<article class="page-wrapper chiller-theme toggled">
  <!-- <app-side-bar></app-side-bar> -->
  <section class="page-content">
    <app-header></app-header>
    <div class="container">
      <div class="focus-container container">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </section>
</article>
