import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const expectedRoles: string[] = next.data['roles'];
        const currentRole = this.authService.role;

        if (this.authService.isLoggedIn && expectedRoles.includes(currentRole)) {
            return true;
        } else {
            this.router.navigate(['home']);
            return false;
        }
    }
}