import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import * as Sentry from "@sentry/angular";
import appVersion from './version.json';

if (environment.production) {
  enableProdMode();
}


Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25,
  tracePropagationTargets: [environment.baseUrl],
  release: appVersion.version,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
